<template>
  <el-popover
    trigger="click"
    placement="bottom-start"
    @show="visible = true"
    @hide="hide">
    <el-select
      multiple
      collapse-tags
      ref="select"
      slot="reference"
      style="width: 100%;"
      placeholder="请选择门店"
      popper-class="treeSelect-input"
      :popper-append-to-body="false"
      :value="tree.value">
      <el-option v-for="item in tree.value" :key="item" :label="item" :value="item"></el-option>
    </el-select>
    <div class="tree-wrap" :style="`width: ${tree.width}px`">
      <el-input placeholder="请输入关键词" v-model="tree.search"></el-input>
      <el-tree
        ref="tree"
        node-key="id"
        show-checkbox
        check-strictly
        highlight-current
        default-expand-all
        check-on-click-node
        :data="tree.data"
        :props="{label: 'name'}"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        @check="handleNodeCheck">
      </el-tree>
    </div>
  </el-popover>
</template>

<script>
  import Api from "@/views/face/api"

  export default {
    name: "treeSelect",
    model: {
      event: 'selectTreeNode'
    },
    props: {
      radio: {type: Boolean, default: false}
    },
    data: () => ({
      visible: false,
      tree: {
        width: '100%',
        data: [],
        value: undefined,
        search: ''
      }
    }),
    methods: {
      getStore() {
        Api.store(this.$route.query.phone).then(res => {
          const data = res.data.stores
          if (data.length) {
            this.tree.data = data
            this.tree.value = [data[0].name]
            this.$nextTick(() => {
              this.$refs.tree.setChecked(data[0].id, true)
              this.$emit('selectTreeNode', this.tree.value.join(','))
              this.$emit('change')
            })
          }
        }).catch(err => {
          console.log(err);
        })
      },
      handleNodeCheck(data, {checkedKeys, checkedNodes}) {
        if (this.radio) {
          checkedKeys.length && checkedKeys.forEach(item => item !== data.id && this.$refs.tree.setChecked(item, false))
          this.tree.value = checkedKeys.length ? [data.name] : []
        } else {
          this.tree.value = checkedNodes.map(item => item.name)
        }
        this.$emit('selectTreeNode', this.tree.value.join(','))
        this.$emit('change')
      },
      filterNode(value, data) {
        if (!value) return true
        return data.name.indexOf(value) !== -1
      },
      hide() {
        this.visible = false
        this.tree.search = ''
      }
    },
    created() {
      this.getStore()
    },
    mounted() {
      this.$nextTick().then(() => {
        this.tree.width = this.$refs.select.$el.offsetWidth - 25
      })
    },
    watch: {
      'tree.search'(n) {
        this.$refs.tree.filter(n)
      }
    }
  }
</script>

<style scoped lang="scss">
  .tree-wrap {
    overflow: auto;
    max-height: 300px;
  }
  ::v-deep {
    .treeSelect-input {
      display: none !important;
    }
    .el-icon-close {
      display: none;
    }
  }
</style>