import http from "@/http"

class Api {
  // 门户
  async store(phone) {
    return await http.get(`/store/${phone}`)
    // return {"error":0,"message":"Success","status":0,"data":{"stores":{"id":1,"pid":0,"name":"华宏汽车集团","children":[{"id":2,"pid":1,"name":"九江一汽大众"},{"id":3,"pid":1,"name":"九江凯迪拉克"},{"id":4,"pid":1,"name":"九江别克"},{"id":5,"pid":1,"name":"九江红旗"},{"id":6,"pid":1,"name":"南康奔驰"},{"id":7,"pid":1,"name":"南康奥迪"},{"id":8,"pid":1,"name":"吉安别克"},{"id":9,"pid":1,"name":"吉安奔驰"},{"id":10,"pid":1,"name":"吉安奥迪"},{"id":11,"pid":1,"name":"宜春奥迪"},{"id":12,"pid":1,"name":"宜春红旗"},{"id":13,"pid":1,"name":"抚州一汽大众"},{"id":14,"pid":1,"name":"抚州奥迪"},{"id":15,"pid":1,"name":"望城奔驰"},{"id":16,"pid":1,"name":"萍乡奔驰"},{"id":17,"pid":1,"name":"质选二手车"},{"id":18,"pid":1,"name":"赣州北京现代"},{"id":19,"pid":1,"name":"赣州奔驰"},{"id":20,"pid":1,"name":"赣州奥迪"},{"id":21,"pid":1,"name":"集团总部"},{"id":22,"pid":1,"name":"青云谱一汽大众"},{"id":23,"pid":1,"name":"青云谱北京现代"},{"id":24,"pid":1,"name":"青云谱奔驰"},{"id":25,"pid":1,"name":"鹰潭一汽大众"},{"id":26,"pid":1,"name":"鹰潭广汽丰田"}]}},"timestamp":1623136971}
  }
  // 性别
  async sex() {
    return await http.get(`/face/gender`)
  }
  // 年龄段
  async age() {
    return await http.get(`/face/age_type`)
  }
  // 客流排行
  async ranking(params) {
    return await http.get('/face/flow_ranking', {params})
  }
  // 客流趋势分析
  async compare(params) {
    return await http.get('/face/compare_analyse', {params})
  }
  // 客流同环比分析
  async trend(params) {
    return await http.get('/face/trend_analyse', {params})
  }
  // 客群分析
  async group(params) {
    return await http.get('/face/group_analyse', {params})
    // return {"code":"0","msg":"success","data":{"entity":null,"genderPie":{"dataList":[{"name":"女性","value":38,"percentage":"31.1","order":1,"id":"female"},{"name":"男性","value":84,"percentage":"68.9","order":2,"id":"male"}],"total":0},"ageTypePie":{"dataList":[{"name":"儿童","value":0,"percentage":"0.0","order":1,"id":"child"},{"name":"青年","value":111,"percentage":"91.7","order":2,"id":"youth"},{"name":"中年","value":9,"percentage":"7.4","order":3,"id":"middle_aged"},{"name":"老年","value":1,"percentage":"0.9","order":4,"id":"old"}],"total":0}}}
  }
}

export default new Api()