<template>
  <el-radio-group class="radio-group" size="mini" v-model="tab" @change="handleTabChange">
    <el-radio-button v-for="item in tabs" :label="item.value" :key="item.value">{{item.label}}</el-radio-button>
  </el-radio-group>
</template>

<script>
  export default {
    name: "tabs",
    props: {
      tabs: {type: Array, default: () => []},
    },
    data: () => ({
      tab: ''
    }),
    methods: {
      handleTabChange(data) {
        this.$emit('handleTabClick', data, this.tabs.indexOf(data))
      }
    },
    created() {
      this.tab = this.tabs[0].value
    },
  }
</script>

<style scoped lang="scss">
  .radio-group {
    margin: 0 auto;
    white-space: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>