import axios from "axios"
import {message} from "@/utils";

const http = axios.create({
  timeout: 15000,
  baseURL: '/api'
})

http.interceptors.request.use(requestSuccess, requestFail)

http.interceptors.response.use(responseSuccess, responseFail)

function requestSuccess(req) {
  return req
}
function requestFail(req) {
  return Promise.reject(req.message)
}

function responseSuccess(res) {
  const {error, msg} = res.data
  error && msg && message(msg, 'error')
  return res.data
}
function responseFail(res) {
  return Promise.reject(res)
}

export default http